import { Alert, Button, Container, TextField } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import AppStateContext from '../contexts/AppStateContext'

import { useNavigate } from 'react-router-dom'
import { ErrorType } from '../types/error-type'
import useApi from '../hooks/useApi'


function Login() {
  const appState = useContext(AppStateContext)
  const api = useApi()
  const { apiBaseUrl, authenticated, iconFile, error, setError, myClubStyles } = appState

  console.log('appState', appState)

  const [email, setEmail] = useState<string>()
  const [password, setPassword] = useState<string>()

  const navigate = useNavigate()

  const login = async () => {
    if (!api || !email || !password) {
      return
    }

    try {
      const response = await api.post('login', {
        email,
        password,
      })

      appState.patchState({
        authenticated: true,
        member: response.data,
        error: null,
      })
    } catch (error: any) {
      if (!error.response || error.response.status === 401) {
        setError(ErrorType.INVALID_LOGIN)
      }
    }
  }

  const goToRegister = () => navigate('/register')

  useEffect(() => {
    if (authenticated) {
      navigate('/')
    }
  }, [])

  return (
    <Container style={{ marginTop: '16px', maxWidth: '600px' }}>
      <div className="flex-vertical flex-form">
        <img width="150px" src={myClubStyles?.logoUrl ? myClubStyles.logoUrl : iconFile} />

        <h3 className='white'>Introduza os seus dados</h3>

        <TextField
          id="outlined-basic"
          required
          label="E-mail"
          variant="filled"
          name="email"
          onChange={(event) => setEmail(event.target.value)}
        />

        <TextField
          id="outlined-basic"
          required
          label="Password"
          variant="filled"
          name="password"
          type="password"
          onChange={(event) => setPassword(event.target.value)}
        />

        <Button variant="contained" onClick={login}>
          Entrar
        </Button>

        {error === ErrorType.INVALID_LOGIN && (
          <Alert severity="error">
            Login inválido. Por favor tente novamente. Se tiver dificuldades em
            autenticar-se, por favor contacte a direção.
          </Alert>
        )}

        {error === ErrorType.USER_ALREADY_REGISTRED && (
          <Alert severity="error">
            Este link já não é válido, pois o membro já se encontra registado na
            plataforma do sócio.
          </Alert>
        )}

        <div className="margin-top white">
          Ainda não tem conta? Clique aqui para criar
        </div>
        <Button
          variant="text"
          onClick={goToRegister}
        >
          Criar conta
        </Button>
      </div>
    </Container>
  )
}

export default Login
