import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import ErrorIcon from '@mui/icons-material/Error'
import FmdBadIcon from '@mui/icons-material/FmdBad'
import moment from 'moment-timezone'
import { Period } from '../../types/pending-period'

import './PeriodsTable.css'
import { Tooltip } from '@mui/material'
import CheckCircle from '@mui/icons-material/CheckCircle'
import HourglassEmpty from '@mui/icons-material/HourglassEmpty'

type Props = {
  periods: Period[]
}

const hasEnded = (period: Period) => {
  return moment().isAfter(period.end)
}

const renderPeriodIcon = (period: Period) => {
  const isMissing = period.status === 'MISSING' || period.status === 'PENDING';
  const periodHasEnded = hasEnded(period);

  const Icon = !isMissing
    ? CheckCircle
    : (
      period.status === 'PENDING' ? HourglassEmpty : (
        periodHasEnded ? ErrorIcon : FmdBadIcon
      )
    )

  const iconColor = !isMissing
    ? 'success'
    : (
      period.status === 'PENDING' ? 'warning' : (
        periodHasEnded ? 'error' : 'warning'
      )
    )

  const tooltipTitle = !isMissing
    ? `Pago em ${moment(period.paid_at).format('D MMM YYYY')}`
    : (
      period.status === 'PENDING' ? 'A aguadar confirmação de pagamento' : (
        periodHasEnded ? 'Pagamento em atraso' : 'Periodo a pagamento'
      )
    )

  return (
    <Tooltip
      title={tooltipTitle}
    >
      <Icon color={iconColor} style={{ fontSize: '18px' }}></Icon>
    </Tooltip>
  )
}

function PeriodsTable({ periods = [] }: Props) {
  return (
    <div className="periods-table-wrapper">
      <TableContainer>
        <Table sx={{ minWidth: 350, maxWidth: 500 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Periodo</TableCell>
              <TableCell align="center">De&nbsp;</TableCell>
              <TableCell align="center">Até&nbsp;</TableCell>
              <TableCell align="right">Valor&nbsp;(€)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {periods.map((period) => (
              <TableRow
                key={period.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left" scope="row">
                  <div className="flex-horizontal" style={{ gap: '6px' }}>
                    {renderPeriodIcon(period)}
                    <span>{period.name}</span>
                  </div>
                </TableCell>
                <TableCell align="center">
                  {moment(period.start).format('D MMM YYYY')}
                </TableCell>
                <TableCell align="center">
                  {moment(period.end).format('D MMM YYYY')}
                </TableCell>
                <TableCell align="right">{period.value}€</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default PeriodsTable
